import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { animated } from "react-spring"

import { Container, Layout, Section } from "../components"
import SEO from "../components/seo"

const ContactPage = props => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Section>
        <Container>
          <h2
            css={`
              text-align: center;
            `}
          >
            Let's Connect!
          </h2>
          <ContactForm>
            <p>
              You have an idea in your mind and looking for execution with pixel
              perfect designs.
            </p>
            <p>Let’s build your idea and give your brand an exposure</p>
            <div
              css={`
                max-width: 100%;
                margin: 0 auto;
                @media (min-width: ${props => props.theme.responsive.sm}) {
                  max-width: 60%;
                }
              `}
            >
              <Form
                name="contact"
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                action="/confirmation"
              >
                <Input type="hidden" name="form-name" value="contact" />
                <Input type="hidden" name="bot-field" />
                <Label>
                  Name
                  <sup
                    css={`
                      color: red;
                    `}
                  >
                    *
                  </sup>
                  <Input type="text" name="name" required />
                </Label>
                <Label>
                  Email
                  <sup
                    css={`
                      color: red;
                    `}
                  >
                    *
                  </sup>
                  <Input type="email" name="email" required />
                </Label>
                <Label>
                  Message
                  <sup
                    css={`
                      color: red;
                    `}
                  >
                    *
                  </sup>
                  <textarea name="message" rows={5} required />
                </Label>
                <button type="submit">Holla at me</button>
              </Form>
            </div>
          </ContactForm>
        </Container>
      </Section>
    </Layout>
  )
}

const ContactForm = styled.div`
  background: var(--white);
  border-radius: 1.6rem;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 1.6rem;
  max-width: 100%;
  @media (min-width: ${props => props.theme.responsive.sm}) {
    max-width: 75%;
  }
  > p {
    color: var(--gray-900);
    text-align: center;
  }
`

const Form = styled(animated.form)`
  box-sizing: border-box;
  border-radius: 0.25rem;
  color: var(--gray-700);
  margin: 0 auto;
  padding: 1.6rem;
  width: 100%;

  > button {
    color: var(--white);
    background-color: var(--primary);
    border: none;
    border-radius: 0.4rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    display: block;
    letter-spacing: 0.08em;
    padding: 0.8rem;
    text-transform: uppercase;
    width: 100%;
    transition: all 0.2s linear;
    &:hover {
      background-color: var(--primary-light);
    }
  }

  @media (min-width: ${props => props.theme.responsive.sm}) {
    width: 100%;
  }
`
const Label = styled.label`
  display: block;
  font-size: 1.2rem;
  margin-bottom: 1.6rem;
  text-transform: uppercase;
  width: 100%;

  > textarea {
    box-sizing: border-box;
    border-radius: 0.25rem;
    border: 1px solid var(--primary);
    display: block;
    font-size: 1.6rem;
    margin-top: 0.5rem;
    padding: 1rem;
    resize: none;
    width: 100%;
    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.05rem var(--primary);
    }
  }
`

const Input = styled.input`
  background-color: #fff;
  border: 1px solid var(--primary);
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: block;
  font-size: 1.6rem;
  margin-top: 0.2rem;
  padding: 1rem;
  width: 100%;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.05rem var(--primary);
  }
`

export const pageQuery = graphql`
  query {
    desktop: file(relativePath: { eq: "trees.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ContactPage
